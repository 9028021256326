/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CampaignStatusEnum = 'CREATED' | 'TO_BE_CONFIRMED' | 'FURTHER_INFO' | 'REJECTED' | 'APPROVED' | 'NOT_APPROVED' | 'IN_PROGRESS' | 'TO_BE_REPORTED' | 'TO_BE_APPROVED' | 'CONFIRMED' | 'DELETED' | 'REPORT_FURTHER_INFO' | 'REPORT_NOT_APPROVED';

export const CampaignStatusEnum = {
    Created: 'CREATED' as CampaignStatusEnum,
    ToBeConfirmed: 'TO_BE_CONFIRMED' as CampaignStatusEnum,
    FurtherInfo: 'FURTHER_INFO' as CampaignStatusEnum,
    Rejected: 'REJECTED' as CampaignStatusEnum,
    Approved: 'APPROVED' as CampaignStatusEnum,
    NotApproved: 'NOT_APPROVED' as CampaignStatusEnum,
    InProgress: 'IN_PROGRESS' as CampaignStatusEnum,
    ToBeReported: 'TO_BE_REPORTED' as CampaignStatusEnum,
    ToBeApproved: 'TO_BE_APPROVED' as CampaignStatusEnum,
    Confirmed: 'CONFIRMED' as CampaignStatusEnum,
    Deleted: 'DELETED' as CampaignStatusEnum,
    ReportFurtherInfo: 'REPORT_FURTHER_INFO' as CampaignStatusEnum,
    ReportNotApproved: 'REPORT_NOT_APPROVED' as CampaignStatusEnum
};

